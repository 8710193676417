<template>
    <b-row>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.user"
                             :options="getUsersList()"
                             name="user"
                             :label-prefix="labelPrefix"
                             validate="required"
                             text-field="fullname"
                             value-field="id"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.template"
                             :options="getEmailTemplates()"
                             name="template"
                             :label-prefix="labelPrefix"
                             text-field="name"
                             value-field="id"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.smtp"
                             :options="getUsersList()"
                             name="smtp"
                             :label-prefix="labelPrefix"
                             text-field="fullname"
                             value-field="id"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <zw-input-group v-model="inputVal.cc"
                            name="cc"
                            :label-prefix="labelPrefix"
            ></zw-input-group>
        </b-col>
        <b-col cols="6">
            <zw-input-group v-model="inputVal.bcc"
                            name="bcc"
                            :label-prefix="labelPrefix"
            ></zw-input-group>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'SendOfferingsListOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('CommonData', ['getEmailTemplates']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>